export default {
    title: 'Retention',
    location: '/people/balanced-co-worker-turnover',
    tabs: [
        {
            name: 'By Country',
            link: `/people/balanced-co-worker-turnover`
        },
        {
            name: 'Trend',
            link: `/people/balanced-co-worker-turnover/:market`
        }
    ]
}